import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import breakPoints from '../helpers/breakPoints';

const StyledHeader = styled.div`
    position: absolute;
    height: 5vh;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    flex: 1;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    @media (max-width: ${breakPoints.mobile}px) {
        font-size: 0.8rem;
    }
`;

const FixedHeader = ({ children }) => <StyledHeader>{children}</StyledHeader>;

FixedHeader.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default FixedHeader;
