const theme = {
    colors: {
        primary: 'rgba(144, 150, 91, 1)',
        secondary: 'rgba(184, 189, 135, 1)',
    },
    fonts: {
        primary: 'Karla',
        secondary: 'Montserrat',
    },
};

export default theme;
