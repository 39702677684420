import React from 'react';
import PropTypes from 'prop-types';

export const FacebookIcon = ({ fill, size }) => (
    <svg fill={fill} width={size} height={size} role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <title>Facebook</title>
        <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
    </svg>
);

FacebookIcon.propTypes = {
    fill: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
};

export const InstagramIcon = ({ fill, size }) => (
    <svg fill={fill} width={size} height={size} role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <title>Instagram</title>
        <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
    </svg>
);

InstagramIcon.propTypes = {
    fill: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
};

export const EtsyIcon = ({ fill, size }) => (
    <svg fill={fill} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <title>Etsy</title>
        <g transform="matrix(0.00300087,0,0,-0.00300018,-0.30496537,24.601107)">
            <path
                d="M 3810,8189 C 2819,8115 1907,7686 1216,6969 -88,5616 -268,3524 784,1965 1642,694 3152,23 4665,241 c 1122,161 2118,785 2751,1724 589,874 810,1947 613,2985 -101,532 -312,1041 -621,1497 -261,385 -635,765 -1009,1026 -764,532 -1677,784 -2589,716 z M 6320,7011 c 0,-22 -13,-255 -30,-518 -16,-263 -37,-599 -45,-748 -9,-148 -18,-281 -21,-293 -5,-24 -5,-24 -143,-20 -191,4 -180,-8 -261,298 -75,281 -105,370 -170,506 -73,151 -139,227 -241,278 -38,19 -92,39 -121,45 -37,8 -342,11 -995,9 -889,-3 -943,-4 -960,-21 -17,-17 -18,-76 -21,-1078 l -2,-1061 472,5 c 666,7 847,27 936,102 68,59 99,150 197,579 39,170 33,166 231,166 h 157 l -7,-997 c -6,-943 -8,-999 -25,-1023 -25,-34 -100,-46 -209,-32 -129,15 -133,22 -212,347 -22,94 -52,195 -66,225 -47,104 -134,169 -263,195 -90,19 -1178,42 -1195,25 -8,-8 -11,-265 -11,-933 0,-867 1,-926 18,-982 49,-158 130,-237 287,-276 74,-19 1573,-19 1720,0 110,14 208,46 288,93 75,44 187,156 246,246 52,78 201,395 314,666 100,239 109,246 288,243 l 119,-2 -2,-25 c -2,-14 -27,-225 -57,-470 -30,-245 -74,-605 -96,-800 -29,-246 -46,-362 -57,-379 l -16,-24 -661,7 c -363,4 -985,11 -1381,16 -1035,13 -1669,12 -2080,-5 -198,-8 -420,-15 -492,-15 h -133 v 168 167 l 237,48 c 131,27 273,60 317,74 140,47 197,110 215,239 16,108 34,2227 27,2979 -10,912 -20,1246 -40,1311 -32,103 -101,166 -226,205 -33,10 -148,35 -257,55 -216,39 -253,52 -274,91 -15,28 -20,285 -6,299 4,4 276,2 605,-5 1928,-39 2759,-34 3679,25 203,13 385,27 416,33 4,0 7,-17 7,-38 z"
                id="path72"
            />
        </g>
    </svg>
);

EtsyIcon.propTypes = {
    fill: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
};
