import React from 'react';
import styled from 'styled-components';

import breakPoints from '../helpers/breakPoints';
import { InstagramIcon, FacebookIcon, EtsyIcon } from './Icons';
import SubscribeFooter from './SubscribeFooter';

const StyledFooter = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    height: 100%;
    align-items: center;
    max-width: 85rem;
    margin: auto;
    padding: 2rem 1rem;

    background-color: white;
    font-size: 2rem;
`;

const SocialContainer = styled.div`
    display: flex;
    flex: 1;
    align-self: flex-end;

    @media (max-width: ${breakPoints.mobile - 1}px) {
        align-self: center;
    }
`;

const FooterHeading = styled.h2`
    align-self: flex-end;

    @media (max-width: ${breakPoints.mobile - 1}px) {
        align-self: center;
    }
`;

const SubscribeContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 2rem 0;
`;

const SubscribeFormContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 1rem 0;
`;

const StyledAddress = styled.address`
    font-size: 1rem;
    font-style: normal;
    align-self: flex-end;
    text-align: right;

    @media (max-width: ${breakPoints.mobile - 1}px) {
        align-self: center;
        text-align: center;
    }
`;

const Footer = () => (
    <StyledFooter className="has-text-centered">
        <FooterHeading className="is-size-5-mobile is-size-3-tablet is-size-3-widescreen">
            Come see what we're up to
        </FooterHeading>
        <SocialContainer>
            <a target="__blank" href="https://www.facebook.com/SororesAromatherapy-103284651289398/">
                <FacebookIcon fill="black" size={30} />
            </a>
            <a
                target="__blank"
                style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}
                href="https://www.instagram.com/sorores.aromatherapy/"
            >
                <InstagramIcon fill="black" size={30} />
            </a>
            <a target="__blank" href="https://www.etsy.com/uk/shop/SororesAromatherapy">
                <EtsyIcon fill="black" size={30} />
            </a>
        </SocialContainer>
        <StyledAddress>
            <strong>You may also want to visit us:</strong>
            <br />
            Sorores at the Old Smiddy
            <br />
            59 Main Street
            <br />
            Dunlop
            <br />
            KA3 4AG
        </StyledAddress>
        <SubscribeContainer>
            <h2 className="is-size-4-mobile is-size-2-tablet is-size-2-widescreen">Subscribe to SORORES</h2>
            <p className="is-size-5-mobile is-size-4-tablet is-size-4-widescreen">We won’t spam you, we promise.</p>
            <p className="is-size-5-mobile is-size-4-tablet is-size-4-widescreen">
                We only send exciting news and special offers. Sign up today for <strong>10% off</strong> your next
                order.
            </p>
            <SubscribeFormContainer>
                <SubscribeFooter />
            </SubscribeFormContainer>
        </SubscribeContainer>
    </StyledFooter>
);

export default Footer;
