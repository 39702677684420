import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { MediaContextProvider, Media } from '../helpers/media';

import logo from '../img/main-logo.svg';
import breakPoints from '../helpers/breakPoints';

const StyledNav = styled.nav`
    display: flex;
    flex: 1;
    margin-top: 5vh;
    position: absolute;
    min-height: 3.25rem;
    background-color: transparent;
    font-size: 2rem;
    width: 100%;
    z-index: 0;

    a,
    Link {
        font-size: 1.3rem;
        height: 100%;
        color: white;

        :hover,
        :focus {
            color: white;
        }
    }

    @media (max-width: ${breakPoints.tablet - 1}px) {
        flex-direction: column;
    }
`;

const Logo = styled.img`
    margin: 1rem;

    &&& {
        max-height: 4rem;
    }

    @media (max-width: ${breakPoints.mobile}px) {
        height: 3rem;
    }

    @media (min-width: ${breakPoints.mobile}px) and (max-width: ${breakPoints.tablet}px) {
        height: 4rem;
    }
`;

const LogoNav = styled.div`
    flex-grow: 1;
    flex-basis: 0;
`;

const MainNavMenu = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-grow: 2;
    flex-basis: 0;
`;

const SecondaryNavMenu = styled.div`
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-basis: 0;
`;

const NavItem = styled.a`
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 1.5;
    padding: 0.5rem 0.75rem;
`;

const MobileNavItem = styled(NavItem)`
    justify-content: center;
    background-color: ${(props) => props.theme.colors.primary};

    &:hover {
        background-color: ${(props) => props.theme.colors.secondary};
    }
`;

const MobileNav = styled.div`
    text-align: center;
`;

const NavContainer = styled.div`
    display: flex;
    flex: 1;
`;

const MobileHeader = () => {
    const [active, setActive] = useState(false);
    return (
        <>
            <StyledNav role="navigation" aria-label="main-navigation">
                <NavContainer>
                    <LogoNav>
                        <NavItem as={Link} to="/">
                            <Logo src={logo} alt="Sorores Aromatherapy" />
                        </NavItem>
                    </LogoNav>
                    <SecondaryNavMenu>
                        <a
                            role="button"
                            className={`navbar-burger burger ${active ? 'is-active' : ''}`}
                            data-target="navMenu"
                            onClick={() => setActive(!active)}
                        >
                            <span />
                            <span />
                            <span />
                        </a>
                    </SecondaryNavMenu>
                </NavContainer>
                {active && (
                    <MobileNav>
                        <MobileNavItem
                            target="__blank"
                            href="https://sorores-aromatherapy.myshopify.com/collections/all"
                        >
                            Shop
                        </MobileNavItem>
                        <MobileNavItem as={Link} to="/about-us">
                            About
                        </MobileNavItem>
                        <MobileNavItem as={Link} to="/contact">
                            Contact
                        </MobileNavItem>
                        <MobileNavItem as={Link} to="/wholesale">
                            Wholesale
                        </MobileNavItem>
                        <MobileNavItem as={Link} to="/custom-order">
                            Custom Order
                        </MobileNavItem>
                    </MobileNav>
                )}
            </StyledNav>
        </>
    );
};

const Header = () => (
    <MediaContextProvider>
        <Media lessThan="md">
            <MobileHeader />
        </Media>
        <Media greaterThan="sm">
            <StyledNav role="navigation" aria-label="main-navigation">
                <LogoNav>
                    <NavItem as={Link} to="/">
                        <Logo src={logo} alt="Sorores Aromatherapy" />
                    </NavItem>
                </LogoNav>
                <MainNavMenu>
                    <NavItem target="__blank" href="https://sorores-aromatherapy.myshopify.com/collections/all">
                        Shop
                    </NavItem>
                    <NavItem as={Link} to="/about-us">
                        About
                    </NavItem>
                    <NavItem as={Link} to="/contact">
                        Contact
                    </NavItem>
                    <NavItem as={Link} to="/wholesale">
                        Wholesale
                    </NavItem>
                    <NavItem as={Link} to="/custom-order">
                        Custom Order
                    </NavItem>
                </MainNavMenu>
                <SecondaryNavMenu />
            </StyledNav>
        </Media>
    </MediaContextProvider>
);

export default Header;
