import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import theme from '../helpers/theme';
import SEO from './SEO';
import Header from './Header';
import Footer from './Footer';
import FixedHeader from './FixedHeader';

import './all.sass';

const GlobalStyle = createGlobalStyle`
    body {
      font-family: 'Montserrat';
      line-height: 1;
    }
    nav, button, a, h1, h2, h3, h4, h5, h6 {
        font-family: 'Karla';
        line-height: 1.2;
    }
    a {
        text-decoration: none;
    }
`;

const TemplateWrapper = ({ children, hideFooter, hideHeader, title, description, image }) => (
    <div>
        <GlobalStyle />
        <SEO title={title} description={description} image={image} />
        <ThemeProvider theme={theme}>
            <FixedHeader>
                FREE domestic shipping on all orders over £35 with Code: FREESHIP
            </FixedHeader>
            {!hideHeader && <Header />}
            {children}
            {!hideFooter && <Footer />}
        </ThemeProvider>
    </div>
);

TemplateWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    hideHeader: PropTypes.bool,
    hideFooter: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
};

export default TemplateWrapper;
