import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import breakPoints from '../helpers/breakPoints';
import { SignUpBtn } from './Buttons';

const SubscribeForm = styled.form`
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: space-around;

    @media (max-width: ${breakPoints.mobile - 1}px) {
        flex-direction: column;
        align-items: center;
    }
`;

const StyledError = styled.div`
    width: 80%;
    text-align: center;
    margin: auto;
    font-size: 0.8rem;
`;

const Success = () => (
    <div>
        <h2>Thank you for subscribing!</h2>
    </div>
);

const Error = ({ msg }) => {
    const createMarkup = () => ({ __html: msg });
    return (
        <StyledError>
            <p dangerouslySetInnerHTML={createMarkup()} />
        </StyledError>
    );
};

Error.propTypes = {
    msg: PropTypes.string,
};

const SubscribeFooter = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [result, setResult] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const handleSubmit = async (e) => {
        setSubmitting(true);
        e.preventDefault();
        addToMailchimp(email, { FNAME: name }).then((response) => {
            setSubmitting(false);
            setResult(response);
        });
    };
    return (
        <>
            {result.result === 'success' && <Success />}
            {result.result === 'error' && <Error msg={result.msg} />}
            {result.result !== 'success' && result.result !== 'error' && (
                <SubscribeForm onSubmit={handleSubmit}>
                    <div className="control">
                        <label className="label" htmlFor="name">
                            Name
                            <input
                                className="input"
                                type="text"
                                name="name"
                                onChange={(e) => setName(e.target.value)}
                                id="name"
                                required
                                disabled={submitting}
                            />
                        </label>
                    </div>
                    <div className="control">
                        <label className="label" htmlFor="email-field">
                            Email
                            <input
                                className="input"
                                type="email"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                id="email-field"
                                required
                                disabled={submitting}
                            />
                        </label>
                    </div>
                    <SignUpBtn className="button" disabled={submitting || !name || !email} type="submit">
                        SUBSCRIBE
                    </SignUpBtn>
                </SubscribeForm>
            )}
        </>
    );
};

export default SubscribeFooter;
