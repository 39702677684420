import styled from 'styled-components';

import breakPoints from '../helpers/breakPoints';
import { generateUrl } from '../helpers/cloudinary';

export const StyledHeroImage = styled.section`
    background-position: center;
    background-size: cover;
    background-color: ${(props) => props.backgroundColor || 'grey'};
    color: white;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => props.vh}vh;

    @media (max-width: ${breakPoints.mobile}px) {
        background-image: url(${(props) => generateUrl(props.image, breakPoints.mobile)});
    }

    @media (min-width: ${breakPoints.mobile}px) and (max-width: ${breakPoints.tablet}px) {
        background-image: url(${(props) => generateUrl(props.image, breakPoints.tablet)});
    }

    @media (min-width: ${breakPoints.tablet}px) and (max-width: ${breakPoints.widescreen}px) {
        background-image: url(${(props) => generateUrl(props.image, breakPoints.widescreen)});
    }

    @media (min-width: ${breakPoints.widescreen}px) and (max-width: ${breakPoints.fullhd}px) {
        background-image: url(${(props) => generateUrl(props.image, breakPoints.fullhd)});
    }

    @media (min-width: ${breakPoints.fullhd}px) {
        background-image: url(${(props) => generateUrl(props.image, 1500)});
    }

    h2 {
        margin-bottom: 1rem;
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.4);
        text-transform: uppercase;
        font-weight: 700;
    }

    h3 {
        max-width: 65%;
        margin-bottom: 1rem;
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.4);
    }

    &:first-of-type {
        > div {
            height: 80vh;
            margin-top: 20vh;
        }
    }

    &:nth-of-type(3) {
        h3 {
            max-width: 40%;
        }
    }
`;

export const HeroTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.right ? 'flex-end' : 'flex-start')};
    text-align: ${(props) => (props.right ? 'right' : 'left')};
    justify-content: center;
    height: 100vh;
    width: 90%;

    line-height: 1.2;

    @media (max-width: ${breakPoints.mobile}px) {
        /* justify-content: center;
        height: 300px; */
    }
`;
