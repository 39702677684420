import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, description, image }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        image
                        siteUrl
                    }
                }
            }
        `
    );

    const defaultImage = site.siteMetadata.siteUrl + site.siteMetadata.image;
    const metaDescription = description || site.siteMetadata.description;
    const metaImage = image || defaultImage;
    const fullyQualifiedImage = `https://res.cloudinary.com/sorores-aromatherapy/image/upload/c_scale,w_768/v1${metaImage}`;

    return (
        <Helmet
            htmlAttributes={{
                lang: `en`,
            }}
            title={title}
            defaultTitle={site.siteMetadata.title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
        >
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />

            {/* General tags */}
            <meta name="image" content={image} />
            <meta name="description" content={metaDescription} />

            {/* OpenGraph tags */}
            <meta property="og:title" content={title} />
            <meta property="og:image" content={fullyQualifiedImage} />
            <meta property="og:description" content={metaDescription} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:image" content={fullyQualifiedImage} />
            <meta name="twitter:description" content={metaDescription} />
        </Helmet>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
};

export default SEO;
