import React from 'react';
import PropTypes from 'prop-types';

export const MultiLineText = ({ description, className }) => {
    const formattedDescription = description
        .split(`\n\n`)
        .map((paragraph) => `<p>${paragraph.replace(/\n/g, `<br>`)}</p>`)
        .join(``);

    return <div className={className} dangerouslySetInnerHTML={{ __html: formattedDescription }} />;
};

MultiLineText.propTypes = {
    description: PropTypes.string.isRequired,
    className: PropTypes.string,
};
