import { createMedia } from '@artsy/fresnel';
import breakPoints from './breakPoints';

const ExampleAppMedia = createMedia({
    breakpoints: {
        xs: 0,
        sm: breakPoints.mobile,
        md: breakPoints.tablet,
        lg: breakPoints.widescreen,
    },
});

// Make styles for injection into the header of the page
export const mediaStyles = ExampleAppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = ExampleAppMedia;
