import styled from 'styled-components';
import { lighten } from 'polished';

export const PrimaryButton = styled.button`
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
    padding: 1rem;
    border-radius: 2px;
    text-transform: uppercase;
    display: inline-block;
    min-width: 150px;
    text-align: center;
    font-size: 1.1rem;

    :hover {
        color: white;
        background-color: ${(props) => lighten(0.1, props.theme.colors.primary)};
    }
`;

export const SignUpBtn = styled.button`
    color: white;
    font-weight: 700;
    margin-top: 20px;
    border-radius: 5px;
    background-color: #5a5a5a;

    :disabled {
        background-color: #5a5a5ab8;
    }

    :hover {
        color: white;
    }
`;
