import { Cloudinary } from 'cloudinary-core';

export const cloudinary = new Cloudinary({
    cloud_name: 'sorores-aromatherapy',
    api_key: '781688959835666',
});

export const generateUrl = (image, width = 1200) =>
    cloudinary.url(image, {
        secure: true,
        transformation: { width, crop: 'scale' },
    });
